<template>
  <section class="flex flex-wrap">
    <div class="flex flex-wrap md:flex-nowrap bg-blue rounded-br-3xl md:pb-16 lg:pb-30 w-full">
      <div
        class="header box-content px-8 pt-8 md:pt-13.5 md:pl-18 md:pr-0 flex w-full md:w-auto lg:flex lg:w-2/3 lg:pt-20 lg:pl-28 lg:items-start xl:pl-40 xl:pt-32"
      >
        <div class="header-inner">
          <h1 class="pb-4 uppercase text-white md:text-[1.75rem]/[2.125rem] lg:text-[2.125rem]/[2.5rem] xl:text-[2.75rem]/[3.125rem]">{{ t("title") }}</h1>
          <p class="text-white lg:pt-2.5 pb-8 xl:pb-24 leading-relaxed lg:leading-6">{{ t("description") }}</p>
        </div>
      </div>
      <div class="inline-flex header-image justify-end relative w-full mr-20 md:m-0">
        <picture class="w-full block -mr-20 md:-mr-0 md:ml-14">
          <img
            class="rounded-tl-3xl rounded-br-3xl mobile-image"
            srcset="
              /images/homepage/header-mobile@3x.png 3x,
              /images/homepage/header-mobile@2x.png 2x,
              /images/homepage/header-mobile@1x.png 1x
            "
            src="/images/homepage/header-mobile.png"
            :alt="t('title')"
          />
          <img
            class="rounded-tl-3xl rounded-br-3xl tablet-image"
            srcset="
              /images/homepage/header-tablet@3x.png 3x,
              /images/homepage/header-tablet@2x.png 2x,
              /images/homepage/header-tablet@1x.png 1x
            "
            src="/images/homepage/header-tablet.png"
            :alt="t('title')"
          />
          <img
            class="rounded-tl-3xl rounded-br-3xl desktop-image"
            srcset="
              /images/homepage/header-desktop@3x.png 3x,
              /images/homepage/header-desktop@2x.png 2x,
              /images/homepage/header-desktop@1x.png 1x
            "
            src="/images/homepage/header-desktop.png"
            :alt="t('title')"
          />
          <img
            class="rounded-tl-3xl rounded-br-3xl hd-image"
            srcset="
              /images/homepage/header-hd@3x.png 3x,
              /images/homepage/header-hd@2x.png 2x,
              /images/homepage/header-hd@1x.png 1x
            "
            src="/images/homepage/header-hd.png"
            :alt="t('title')"
          />
        </picture>
      </div>
    </div>
    <div class="bg-blue w-full rounded-br-4xl mr-20 h-10 md:hidden">&nbsp;</div>
  </section>
</template>

<script>
export default {
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    return { t, locale, localePath };
  },
};
</script>

<style lang="scss" scoped>
.header {
  @media (min-width: 768px) {
    min-width: 300px;
  }

  &-inner {
    width: 100%;
  }

  &-image {
    height: fit-content;

    @media (min-width: 768px) {
      margin-right: -39.45vw;
    }

    @media (min-width: 1024px) {
      margin-right: -33.33vw;
    }

    @media (min-width: 1366px) {
      margin-right: -456px;
    }
  }

  h1 {
    max-width: 296px;

    @media (min-width: 768px) {
      max-width: 300px;
    }

    @media (min-width: 1024px) {
      max-width: 516px;
    }

    @media (min-width: 1536px) {
      max-width: 685px;
    }
  }

  p {
    max-width: 269px;

    @media (min-width: 768px) {
      max-width: 292px;
    }

    @media (min-width: 1024px) {
      max-width: 441px;
    }

    @media (min-width: 1024px) {
      max-width: 605px;
    }
  }
}

section {
  @media (min-width: 768px) {
    margin-right: 39.45vw;
  }

  @media (min-width: 1024px) {
    margin-right: 33.33vw;
  }

  @media (min-width: 1366px) {
    margin-right: 456px;
  }

  picture {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 683px;
    }

    @media (min-width: 1366px) {
      max-width: 959px;
    }
  }
}

.mobile-image {
  display: none;

  @media only screen and (max-width: "767px") {
    display: block;
  }
}

.tablet-image {
  display: none;
  max-width: 438px;
  @media only screen and ((max-width: "1023px") and (min-width: "768px")) {
    display: block;
  }
}

.desktop-image {
  display: none;

  @media only screen and ((max-width: "1355px") and (min-width: "1024px")) {
    display: block;
  }
}

.hd-image {
  display: none;

  @media only screen and (min-width: "1356px") {
    display: block;
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "title": "LE PORTAIL DE LA LUTTE CONTRE LE TABAGISME ET LE VAPOTAGE AU QUÉBEC",
    "description": "Apprenez-en plus sur ces enjeux de santé publique, découvrez comment intervenir dans vos milieux afin d’en prévenir l’usage et inscrivez-vous à J’ARRÊTE pour arrêter de fumer ou de vapoter pour de bon!"
  },
  "en": {}
}
</i18n>
