<template>
  <div class="relative touch-none overflow-x-auto [overscroll-behavior-x:contain] slider-container" v-bind="attrs">
    <div class="inline-flex flex-nowrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { useAttrs } from "vue";

export default {
  name: "SliderContainer",
  setup() {
    const attrs = useAttrs();
    return { attrs };
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  .snap-x {
    scroll-snap-type: x proximity;

    :deep(div) {
      scroll-snap-align: start;
    }
  }
}
</style>
