<template>
  <section class="relative pl-8 md:pl-18 lg:pl-28 mt-15 xl:mx-auto xl:px-0 xl:mt-21">
    <div class="flex justify-between items-center mb-6 xl:mb-11">
      <h2 class="uppercase">{{ t("title") }}</h2>
      <div class="border-b border-white hidden md:flex mx-8 grow"></div>
      <button-basic-link
        :to="localePath({ name: 'information-subpage-index-category-news' })"
        class="mr-8 px-8 h-11.5 items-center justify-center bg-black text-white rounded-br-none font-semibold flex self-end md:inline-flex md:mr-8.3/100vw hover:bg-white hover:text-black transition duration-300 xl:mr-0"
      >
        {{ t("see-all") }}
      </button-basic-link>
    </div>
    <slider-container :id="`slider-${uuid}`" ref="slider">
      <card-post v-for="post in posts" :key="post.id" :post="post" class="mr-5 last:mr-18 lg:last:mr-0"></card-post>
    </slider-container>
    <div class="flex justify-end md:hidden">
      <button-basic-link
        :to="localePath({ name: 'information-subpage-index-category-news' })"
        class="mt-7.5 mr-8 px-8 h-11.5 items-center justify-center bg-black text-white rounded-br-none font-semibold flex self-end hover:bg-white hover:text-black transition duration-300"
      >
        {{ t("see-all") }}
      </button-basic-link>
    </div>
  </section>
</template>

<script>
import ButtonBasicLink from "~/components/Buttons/ButtonBasicLink";
import CardPost from "~/components/Cards/CardPost";
import SliderContainer from "~/components/Sliders/SliderContainer";

export default {
  name: "SectionNews",
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    const { id } = useAttrs();

    return { t, locale, localePath, uuid: id };
  },
  components: {
    CardPost,
    ButtonBasicLink,
    SliderContainer,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  @media (min-width: 1536px) {
    max-width: 1140px;
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "title": "Dernières actualités",
    "see-all": "Voir tout"
  },
  "en": {
    "see-all": "See all",
    "title": "..."
  }
}
</i18n>
