<template>
  <a
    :href="href"
    target="_blank"
    class="card bg-white cursor-pointer rounded-l-lg rounded-t-lg transition transition-border ease-in-out h-auto lg:first:ml-0 first:ml-0 ml-5 md:mr-0 md:last:mr-18 lg:last:mr-0 bg-gradient-to-b from-dark-blue-600 to-dark-blue-800"
  >
    <div class="flex justify-end flex-col h-full">
      <div class="h-full flex flex-col justify-center items-center">
        <img :src="logo" class="w-24"/>
      </div>
      <button-basic class="flex-end font-bold w-full self-end transition duration-200 bg-black hover:bg-dark-blue" target="_blank">
        {{ t("start") }}
      </button-basic>
    </div>

  </a>
</template>

<script>
import ButtonBasic from "~/components/Buttons/ButtonBasic.vue";
export default {
  name: "SectionQuitCtaHome",
  components: {
    ButtonBasic,
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    return { t, locale, localePath };
  },
  computed: {
    href() {
      return this.locale === "fr"
        ? "https://www.quebecsanstabac.ca/jarrete/inscription"
        : "https://www.quebecsanstabac.ca/iquitnow/registration";
    },
    logo() {
      return this.locale === "fr"
        ? "/images/quit/logo_fr.png"
        : "/images/quit/logo_en.png"
    }
  },
};
</script>

<style scoped>
.card {
  width: 270px;
  min-width: 270px;
  position: relative;
}

.cta {
  position: absolute;
  top: 20px;
  left: 30px;
  max-width: 153px;
}
</style>

<i18n lang="json">
{
  "fr": {
    "start": "Je m'inscris",
    "i-quit": "C'est décidé : j'arrête!"
  },
  "en": {
    "start": "Start",
    "i-quit": "That's it, I Quit!"
  }
}
</i18n>
